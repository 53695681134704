<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>专业专家管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有专家信息" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="expertManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增专家信息" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addExpert"
                :model="newExpert"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="姓名：" prop="name">
                  <Input v-model="newExpert.name" placeholder="" />
                </FormItem>
                <FormItem label="职称：" prop="title">
                  <Input v-model="newExpert.title" placeholder="" />
                </FormItem>
                <FormItem label="专业：" prop="major">
                  <Input v-model="newExpert.major" placeholder="" />
                </FormItem>
                <FormItem label="时间：" prop="evaluate_time">
                  <DatePicker
                    type="year"
                    placeholder=""
                    style="width: 200px"
                    v-model="newExpert.evaluate_time"
                  ></DatePicker>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addExpert')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addExpert')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含专业专家关数据的excel文件：
                  <a href="/public/templates/ExpertTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/expert_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>专家信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterExpert"
                :model="currentExpert"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="姓名：" prop="name">
                  <Input v-model="currentExpert.name" placeholder="" />
                </FormItem>
                <FormItem label="职称：" prop="title">
                  <Input v-model="currentExpert.title" placeholder="" />
                </FormItem>
                <FormItem label="专业：" prop="major">
                  <Input v-model="currentExpert.major" placeholder="" />
                </FormItem>
                <FormItem label="时间：" prop="evaluate_time">
                  <DatePicker
                    type="year"
                    placeholder=""
                    style="width: 200px"
                    v-model="currentExpert.evaluate_time"
                  ></DatePicker>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterExpert')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function expertFac() {
  return {
    id:0,
    name:'',
    title:'',
    evaluate_time:'',
    major:'',
  };
}

export default {
  name: "expertManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      columnsFormatData: [
        {
          title: "专家姓名",
          key: "name",
          align: "center",
        },
        {
          title: "职称",
          key: "title",
          align: "center",
          width: 120,
        },
        {
          title: "专业",
          key: "major",
          align: "center",
          width: 120,
        },
        {
          title: "评价时间",
          key: "evaluate_time",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
            
                },
                new Date(params.row.evaluate_time).getFullYear()+'年'
              ),
            ]);
          },
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      newExpert: expertFac(),
      currentExpert:expertFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入姓名！",
            trigger: "blur",
          },
        ],
        evaluate_time: [
          {
            required: true,
            type: "date",
            message: "请选择评价时间！",
            trigger: "blur",
          },
        ],
      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addExpert");
          form.append("newExpert", JSON.stringify(this.newExpert));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/expert_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("专家信息添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterExpert");
          form.append("currentExpert", JSON.stringify(this.currentExpert));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/expert_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("专家信息修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条专家信息：",
        content:
          "<p>" +
          "专家姓名：" +
          value.name +
          "</p>" +
          "<p>" +
          "专家职称：" +
          value.title +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/expert_alter", {
          userToken: userToken,
          askData: "delExpert",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentExpert = expertFac();
        this.currentExpert.id = value.id;
        this.currentExpert.name = value.name;
        this.currentExpert.title = value.title;
        this.currentExpert.major = value.major;
        this.currentExpert.evaluate_time = value.evaluate_time;
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>